<template>
  <div
    style="background-size: cover; min-height:100%;"
    :style="bgurl"
  >
    <div class="navbar-container d-flex align-items-center grosor mb-3">
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <b-link
            class="navbar-brand"
            to="/"
          >
            <span class="brand-logo">
              <b-img
                v-if="checkDomain()"
                src="@/assets/images/logo/coca_logo.png"
                alt="Logo"
                style="max-width: 350px"
              />
              <b-img
                v-else
                src="@/assets/images/logo/logo-brandme.png"
                alt="Logo"
                style="max-width: 100px"
              />
            </span>
          </b-link>
        </li>
      </ul>
    </div>
    <div class="logoCocaResume w-75 col-md-6 mr-auto ml-auto p-0 ">
      <div class="text-center img-invite">
        <h2><strong>{{name}}</strong></h2>
        <img
        :src="imgInfluencer"
        :alt="name"
        >
      </div>
      <b-row
        style="padding: 2% 5%; align-items:center"
      >
        <b-col
          md="3"
          class="logoCocaBrand objetfit"
        >
          <img
            :src="imgLogo"
            alt=""
          >
        </b-col>
        <b-col>
          <h4>Bienvenid@ a esta colaboración!</h4>
        </b-col>
      </b-row>
      <div style="padding: 1% 5%;">
        <div class="plusGrand" v-if="campaignInfo.custom_message != '' && campaignInfo.show_custom_message != false" v-html="campaignInfo.custom_message" style="padding: 1% 5%;"></div>
        <div >
          <p>Estamos emocionados de trabajar contigo en esta nueva campaña.</p>
          <p>Usamos BrandMe como nuestra herramienta favorita para gestionar todos nuestros programas de influencer marketing con el objetivo de ahorrar tiempo entre ambas partes pudiendo recibir métricas, estadísticas y analytics de tus piezas de contenido. ( Por ejemplo views e impresionas en tus Instagram Stories ).</p>
          <p>Para tener una mejor experiencia te recomendamos vincular tus redes sociales a BrandMe incluyendo Instagram Business.</p>
          <p>Conoce todos los detalles y un completo gestor para administrar tus contenidos a continuación.</p>
        </div>
        <div class="text-center mb-1">
        <router-link
          :to="url_details"
          target="_blank"
        >
          <b-button
            variant="outline-primary"
          >
            Entrar a la campaña
          </b-button>
        </router-link>
        </div>
      </div>
    </div>
    <div
      class="flex footerInvitation mr-auto ml-auto mt-2"
      style="align-items:center"
    >
      <p
        class="mr-2 mb-0"
        style="color:white;"
      >powered by</p>
      <img src="https://legacy.platform.brandme.la/images/logoNewSmall2.png" class="imgBrand">
    </div>
  </div>
</template>

<script>
import {
  BLink, BImg, BRow, BCol, BButton
} from 'bootstrap-vue'
import config from '@/axiosCode'
import utils from '@/libs/utils';

export default {
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
    BButton
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      bgurl: '',
      campaignInfo: '',
      url_details: '',
      imgInfluencer: '',
      imgLogo: '',
      name: '',
      rutaDominio: window.location.host
    }
  },
  created () {
    if (this.$route.params.uuid) this.url_details = `/invitationDetails/${this.$route.params.uuidInfluencer}/${this.$route.params.uuid}`
    else this.url_details = `/external/details/${this.$route.params.slug}/${this.$route.params.network}/${this.$route.params.username}`

    const vue = this
    vue.checkInvitation()
    this.backInvitation()
    const url_service = this.$route.params.uuid ? `${config.apiCore}/api/v1/campaign/${vue.$route.params.uuid}/external/${vue.$route.params.uuidInfluencer}` : `${config.apiCore}/api/v1/campaign/external/${this.$route.params.slug}/${this.$route.params.network}/${this.$route.params.username}/`

    this.$http.get(url_service)
      .then(response => {
        vue.campaignInfo = response.data.response ? response.data.response : {}
        vue.imgInfluencer = response.data.response.campaign_influencer.influencer.profile_url
        vue.imgLogo = response.data.response.logo
        vue.name = response.data.response.name
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    checkDomain() {
      return this.rutaDominio.includes('coca')
    },
    checkInvitation() {
      if (utils.isInvitation()) {
        setTimeout(() => {
          document.getElementById('hubspot-messages-iframe-container').classList.add('hidden')
        }, 500)
      }
    },
    backInvitation () {

      if (this.rutaDominio.includes('coca')) {
        document.getElementsByTagName('body')[0].style.backgroundImage = 'url(https://legacy.platform.brandme.la/images/PatternCocaCola-01.png)'
        document.getElementsByTagName('body')[0].style.backgroundSize = 'cover'
      } else {
        document.getElementsByTagName('body')[0].style.backgroundImage = 'url(https://legacy.platform.brandme.la/images/vuexy-login-bg.jpg)'
        document.getElementsByTagName('body')[0].style.backgroundSize = 'cover'
      }
    }
  }
}
</script>

<style>
    .grosor{
        padding: 0.8rem 2rem;
        justify-content: center;
    }
    header{
        display: none !important;
    }

    footer{
        display: none !important;
    }

    #theme-customizer, .vx-navbar-wrapper{
        display: none !important;
    }

    .router-content{
        margin-top: 0 !important;
    }

    .logoCoca{
        width: 30%;
        text-align: center;
    }

    .logoCoca img{
        width: 27%;
    }

    .logoCocaResume{
        width: 50%;
        background: white;
        border-radius: 10px;
    }

    .img-invite{
        width: 100%;
        background:#0077ee;
        border-radius: 10px 10px 0px 0px;
        padding-bottom: 2%;
    }

    .img-invite h2{
        color: white;
        padding: 1%;
    }

    .img-invite img{
        width:12%;
    }

    .logoCocaBrand{
        width: 20%;
        margin-right: 3%;
    }

    .logoCocaBrand img{
        width: 80%;
    }

    .footerInvitation{
        width: 50%;
    }

    .footerInvitation img{
        width:6rem;
    }

    .objetfit{
        border-radius: 50%;
    }

    .objetfit > img{
        width: 8vw ;
        height: 8vw;
        border-radius: 50%;
        margin: auto;
        object-fit: cover;
    }
</style>
